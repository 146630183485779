<template>
  <div>
    <NavigationBar titel="Audit"></NavigationBar>
    <section>
      <TippCard
        hintID="Admin_Audit_1_V1"
        text="Das Audit speichert alle wichtigen Ereignisse innerhalb deiner Organisation. Dokumentierte Ereignisse können in der Dokumentation eingesehen werden."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-timeline-text" title="Logs"></Subheader>
              <v-divider></v-divider>
              <div class="mt-7">
                <v-data-table
                  :headers="headers"
                  :items="logs"
                  item-key="meta.id"
                  sort-by="log.timestampFirebaseFormat"
                  sort-desc
                  :footer-props="footerProps"
                  :items-per-page="25"
                  class="elevation-2"
                  max-height="800px auto"
                >
                  <template v-slot:[`item.log.details`]="{ item }">
                    {{ textOutput(item.log.id) }}
                  </template>
                  <template v-slot:[`item.meta.id`]="{ item }">
                    <code>{{ item.meta.id }}</code>
                  </template>
                  <template
                    v-slot:[`item.log.timestampFirebaseFormat`]="{ item }"
                  >
                    {{ item.log.timestamp }}
                  </template>
                  <!-- <td>
                        <v-icon :color="iconColorOutput(item.ereignis.art)">{{ iconMDIOutput(item.ereignis.art) }}</v-icon>
                        {{ item }}
                        <v-badge
                          bordered
                          overlap
                          bottom
                          :color="statusColor(item.status)"
                          :icon="statusIcon(item.status)"
                          class="mr-4"
                        >
                          <v-avatar size="42" color="grey lighten-2">{{
                            initialien(item.displayName)
                          }}</v-avatar>
                        </v-badge>

                        {{ item.ereignis.beschreibung }}
                      </td> -->
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <!-- <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12" >
              <Subheader
                icon="mdi-information"
                title="Legende"
              ></Subheader>
              <div class="mt-7">
                HIER LEGENDE EINFÜGEN
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section> -->
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <router-view />
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import { ADMIN } from "@/store/modules.js";
import { GET_LOGS } from "@/store/action-types.js";
// import { ADMINISTRATION_AUDIT_read } from "@/data/permission-types.js";

export default {
  name: "admin-audit",
  components: {
    NavigationBar,
    Subheader,
    TippCard,
    SupportTools,
  },
  data() {
    return {
      headers: [
        { text: "Zeitpunkt", value: "log.timestampFirebaseFormat" },
        //{ text: "Ereignisart", value: "ereignis.art" },
        {
          text: "Kurzbeschreibung",
          align: "start",
          sortable: false,
          value: "log.details",
        },
        { text: "ID", value: "meta.id", sortable: false },
      ],
      footerProps: {
        "items-per-page-options": [15, 25, 50, -1],
      },
    };
  },
  computed: {
    logs() {
      return this.$store.state.admin.logs;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "logs", data: this.logs }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_LOGS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    textOutput(item) {
      switch (item) {
        case "ORG_CREATE":
          return "Organisation erstellt";
        case "ORG_UPDATE":
          return "Organisationsinformationen/-konfiguration aktualisiert";
        case "ORG_DELETE_REQUEST":
          return "Anfrage Organisation zu löschen";
        case "ORG_DELETE":
          return "Organisation gelöscht";
        case "ORG_OWNER_CHANGED":
          return "Organisationsbesitzer gewechselt";
        case "ORG_TRIAL_STARTED":
          return "Testphase gestartet";
        case "ORG_TRIAL_ENDED":
          return "Testphase beendet";
        case "ORG_SUBSCRIPTION_STARTED":
          return "Produkt-Plan gestartet";
        case "ORG_SUBSCRIPTION_RENEWED":
          return "Produkt-Plan verlängert";
        case "ORG_SUBSCRIPTION_CANCELED":
          return "Produkt-Plan gekündigt";
        case "ORG_SUBSCRIPTION_ENDED":
          return "Produkt-Plan abgelaufen";
        case "ORG_PAYMENT_INFO_UPDATE":
          return "Abrechnungs-/Konto-/Zahlungsinformationen aktualisiert";
        case "INVITE_CREATE":
          return "Einladung erstellt/versendet";
        case "INVITE_RENEWED":
          return "Einladung erneuert";
        case "INVITE_REVOKE":
          return "Einladung erneuert";
        case "MEMBERSHIP_CREATE":
          return "Mitgliedschaft erstellt/Beitritt zu Organisation";
        case "MEMBERSHIP_ARCHIVED":
          return "Mitgliedschaft archiviert";
        case "MEMBERSHIP_DELETE":
          return "Mitgliedschaft entfernt";
        case "MEMBERSHIP_LEAVE":
          return "Mitgliedschaft aufgegeben/Organisation verlassen";
        case "SUPPORT_ACCESS_START":
          return "Supportzugriff freigegeben";
        case "SUPPORT_ACCESS_END":
          return "Supportzugriff widerrufen";
        case "SUPPORT_AGENT_START":
          return "Support-Agent betritt Organisation";
        case "SUPPORT_AGENT_END":
          return "Support-Agent verlässt Organisation";
        case "API_KEY_CREATE":
          return "API-Schlüssel erstellt";
        case "API_KEY_UPDATE":
          return "API-Schlüssel aktualisiert (Konfiguration angepasst)";
        case "API_KEY_RENEW":
          return "API-Schlüssel erneuert";
        case "API_KEY_REVOKE":
          return "API-Schlüssel widerrufen";
        default:
          return "";
      }
    },
    iconMDIOutput(item) {
      switch (item) {
        case "":
          return "";
        case "b":
          return "";
        case "c":
          return "";
        case "d":
          return "";
        default:
          return "";
      }
    },
    iconColorOutput(item) {
      switch (item) {
        case "":
          return "";
        case "b":
          return "";
        case "c":
          return "";
        case "d":
          return "";
        default:
          return "";
      }
    },
  },
};
</script>
